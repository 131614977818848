<template>
    <form @submit="submit">
        <loading
              :active.sync="isLoading"
              :is-full-page="false"
              :color="this.$config.LOADER.color"
              :opacity="this.$config.LOADER.opacity"
              :background-color="this.$config.LOADER.backgroundColor"
              :z-index="10000"
            ></loading>

        <b-form-group
              id="input-group-customer"
              label="Засварлар огноо"
            >
            <date-picker
                v-model="form.repair_date"
                :config="{ format: 'YYYY-MM-DD', useCurrent: false }"
                required
                placeholder="Төлсөн огноо"
              ></date-picker>
        </b-form-group>

        <b-form-group
              id="input-group-customer"
              label="Шалтгаан"
            >
            <b-textarea required v-model="form.descr" placeholder="Тайлбар бичнэ үү"></b-textarea>
        </b-form-group>

        <b-form-group class="mb-0">
            <b-button variant="primary" type="submit"> Хадгалах</b-button>
        </b-form-group>
    </form>
</template>

<script>
export default {
  name: "UnduelossRepair",
  props: ["customer"],
  data: function() {
    return {
        form: {
            repair_date: null,
            descr: ''
        },
      isLoading: false,
    };
  },
  mounted() {
    this.$data.form = {
        repair_date: null,
        descr: ''
    }
  },
  methods: {
    submit: function() {
        this.isLoading = true;
        
        this.$http
        .post(
            this.$config.API_URL + "RepairWebService/repair_undueloss",
            { data: JSON.stringify({
                customerId: this.customer.id,
                repairDate: this.form.repair_date,
                description: this.form.descr
            }) },
            { headers: this.$store.getters.httpHeader, emulateJSON: true }
        )
        .then(
            (response) => {
              this.isLoading = false;
              if (response.body.responseResultType == "SUCCESS") {
                  this.showToast(
                  "Амжилттай",
                  "Амжилттай хадгалагдлаа",
                  "success"
                  );
                  this.onReset(evt);
              } else {
                  this.showToast("Алдаа", "Хадгалах үед алдаа гарлаа", "danger");
              }
            },
              (response) => {
              this.isLoading = false;
            }
        );
    }
  },
};
</script>
